import React from "react";
import * as styles from "./body.module.scss";
import { navigate } from "gatsby";



const Section = () => {

    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

  return (
    <div className={styles.container}>
        <div  className={styles.left}>
            <h4>Video Mockups</h4>
            <ul>
                <li onClick={() => scrollToElement("intro")}>Intro</li>
                <li onClick={() => scrollToElement("how-to")}>How to</li>
                <li onClick={() => scrollToElement("use-cases")}>Use cases</li>
                {/*  <li onClick={() => scrollToElement("more")}>More</li> */}
            </ul>
            <a onClick={() => window.location = "/editor/3D"} className={styles.button}>
                Get Started
            </a>
        </div>
        <div  className={styles.right}>
            <div id="intro" className={styles.intro}>
                <h2>
                    Device Mockups with Videos
                </h2>
                <p>
                    Videos help to visually explain the application to the user. By inserting such videos into devices you can create beautiful app demos that are perfect for promotional content. Continue reading to find out how to create such phone video mockups and why you should be using them.
                </p>
            </div>
            <div id="how-to" className={styles.howTo}>
                <h2>How to add videos to device mockups</h2>
                <p>
                    Watch the video below to see how to create video mockups.
                </p>
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/MoqEA2rfSdg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className={styles.video}>
                    
                </div>
                <div className={styles.step}>
                    <h4>1. Select your device</h4>
                    <p>
                        Choose a device from Android phone & tablet, laptop, iPhone, iPad and MacBook devices.
                    </p>
                </div>
                <div className={styles.step}>
                    <h4>2. Upload your video</h4>
                    <p>
                        Upload mp4 formatted video or image media to replace the mockup placeholder.
                    </p>
                </div>
                <div className={styles.step}>
                    <h4>3. Export</h4>
                    <p>
                        Choose between mp4, webm or png sequence formats to export your device video mockup.
                    </p>
                </div>
            </div>
            <div id="use-cases" className={styles.useCases}>
                <h2>Use Cases</h2>
                <p>Adding a device frame to your videos makes your presentation look more professional. </p>
                <div className={styles.grid}>
                    <div className={styles.useCase}>
                        <h4>
                            Portfolio Shots
                        </h4>
                        <p>Embed videos into devices to create crisp mockups to showcase your portfolio. Use it on Dribbble, Behance and other social media.</p>
                    </div>
                    <div className={styles.useCase}>
                        <h4>
                            Presentations
                        </h4>
                        <p>Video Mockups are better at explaining app idea and user interactions.</p>
                    </div>
                </div>
            </div>
            <div id="more" className={styles.more}>
            </div>
        </div>
    </div>
  );
};

export default Section;