import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./header.module.scss";
import TextLoop from "react-text-loop";
import { navigate } from "gatsby";
import Example from "../../Homepage/Header/Example/Example"


const CTA = () => {
    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

    return(
        <div className={styles.buttons}>
            <a onClick={() => window.location = "/editor/3D"} className={styles.button}>
                Get Started
            </a>
        </div>
    )
}


const Hero = () => {

  return (
    <div className={styles.container}>
        <h1>
            Add <strong>Video</strong> to your <span className={styles.textloop}>
                <TextLoop mask={true} 
                    children={["Device", "iPhone", "Tablet", "Smartphone", "MacBook", "Laptop", "iPad", "Mobile Phone"]} />
                </span> Mockups
        </h1>
        <h3>
            The simplest way to insert video & images into your device mockups.
        </h3>
        <CTA />
        <Example />
    </div>
  );
};

export default Hero;
